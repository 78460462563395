import React from 'react';


const experienceData = [
    {
        title: 'Consultant v oddělení Business Process Management',
        company: 'Neit Consulting',
        time: '2023 - současnost',
        description: 'Optimalizace a automatizace obchodních procesů, zlepšení efektivity a podpora digitální transformace firem.'
    },
    {
        title: 'Web Developer',
        company: 'Na volné noze',
        time: '2023 - současnost',
        description: 'Vývoj statických webových stránek, osobní portfolia, weby pro různé služby.'
    },
    {
        title: 'IT podpora',
        company: 'TOP Distribution',
        time: '2022 - 2023',
        description: 'Zpracování dat, práce s interními systémy, vývoj webových stránek v HTML, CSS a JavaScriptu.'
    },
    {
        title: 'Externista',
        company: 'Národní rozvojová banka',
        time: '2021',
        description: 'Převod klientských informací z Excelu do interního systému banky.'
    },
    {
        title: 'Data Experience',
        company: 'NEWTON Media',
        time: '2019 - 2020',
        description: 'Přenos informací mezi systémy, práce v programu K2 s umělou inteligencí pro určení relevance článků.'
    }
];

const ExperienceItem = ({ title, company, time, description }) => (
    <div className="position-relative mb-4">
        <span className="bi bi-arrow-right fs-4 text-light position-absolute" style={{ top: '-5px', left: '-50px' }}></span>
        <h5 className="mb-1">{title}</h5>
        <p className="mb-2">{company} | <small>{time}</small></p>
        <p>{description}</p>
    </div>
);

const Experience = () => {
    return (
        <section className="py-5 wow fadeInUp" data-wow-delay="0.1s">
            <h2 className="title pb-3 mb-5">Pracovní zkušenost</h2>
            <div className="border-start border-2 border-light pt-2 ps-5">
                {experienceData.map((experience, index) => (
                    <ExperienceItem key={index} {...experience} />
                ))}
            </div>
        </section>
    );
};

export default Experience;
