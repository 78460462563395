import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInLeft, fadeInRight } from 'react-animations';
import Header from './components/Header';
import About from './components/About';
import Skills from './components/Skills';
import Experience from './components/Experience';
import Education from './components/Education';
import Interests from './components/Interests';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';

const FadeInLeftAnimation = keyframes`${fadeInLeft}`;
const FadeInRightAnimation = keyframes`${fadeInRight}`;

const AnimatedLeftColumn = styled.div`
  animation: 1s ${FadeInLeftAnimation};
`;

const AnimatedRightColumn = styled.div`
  animation: 1s ${FadeInRightAnimation};
`;

function App() {
    return (
        <div className="App">
            <div className="container">
                <div className="row g-5">
                    <AnimatedLeftColumn className="col-lg-4 sticky-lg-top vh-100">
                        <Header />
                    </AnimatedLeftColumn>
                    <AnimatedRightColumn className="col-lg-8">
                        <About />
                        <Skills />
                        <Experience />
                        <Education />
                        <Interests />
                        <Services />
                        <Portfolio />
                        <Contact />
                        <Footer />
                    </AnimatedRightColumn>
                </div>
            </div>
        </div>
    );
}

export default App;
