import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInDown } from 'react-animations';

const FadeInDownAnimation = keyframes`${fadeInDown}`;

const FadeInDownDiv = styled.div`
  animation: 2s ${FadeInDownAnimation};
`;

const Header = () => {
    return (
        <FadeInDownDiv className="d-flex flex-column h-100 text-center overflow-auto shadow">
            <img
                className="w-100 img-fluid mb-4"
                width="250"
                height="250"
                src="img/profile.webp"
                alt="Profile"
            />
            <h1 className="text-primary mt-2">Jakub Hrdinka</h1>
            <div className="mb-4" style={{ height: '22px' }}>
                <h4 className="typed-text-output d-inline-block text-light"></h4>
                <div className="typed-text d-none">
                    Web Designer, Web Developer, Front End Developer
                </div>
            </div>
            <div className="d-flex justify-content-center mt-auto mb-3">
                <a className="btn btn-secondary btn-square mx-1" href="https://www.facebook.com/kuba.hrdinkaborec">
                    <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-secondary btn-square mx-1" href="https://www.instagram.com/kuba.hrdinka/">
                    <i className="fab fa-instagram"></i>
                </a>
                <a className="btn btn-secondary btn-square mx-1" href="https://www.linkedin.com/in/jakub-hrdinka-ba5614153/">
                    <i className="fab fa-linkedin-in"></i>
                </a>
                <a className="btn btn-secondary btn-square mx-1" href="https://twitter.com/Jakub_H39">
                    <i className="fab fa-twitter"></i>
                </a>
            </div>
            <div className="d-flex align-items-end justify-content-between border-top">
                <a href="Jakub-Hrdinka-zivotopis.pdf" className="btn w-50 border-end">Stáhnout CV</a>
                <a href="mailto:jakub@hrdnk.cz" className="btn w-50 btn-scroll">Kontaktujte mě</a>
            </div>
        </FadeInDownDiv>
    );
};

export default Header;
