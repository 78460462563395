import React from 'react';

const Footer = () => {
    return (
        <section className="wow fadeIn" data-wow-delay="0.1s">
            <div className="bg-secondary text-light text-center p-5">
                <div className="d-flex justify-content-center mb-4">
                    <a className="btn btn-secondary btn-square mx-1" href="https://www.facebook.com/kuba.hrdinkaborec">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="btn btn-secondary btn-square mx-1" href="https://www.instagram.com/kuba.hrdinka/">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a className="btn btn-secondary btn-square mx-1" href="https://www.linkedin.com/in/jakub-hrdinka-ba5614153/">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a className="btn btn-secondary btn-square mx-1" href="https://twitter.com/Jakub_H39">
                        <i className="fab fa-twitter"></i>
                    </a>
                </div>
                <p className="m-0">&copy; Všechna práva vyhrazena 2024.</p>
            </div>
        </section>
    );
};

export default Footer;
