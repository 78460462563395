import React from 'react';

const hobbiesLeft = ['Fotbal', 'Turistika', 'Cestování'];
const hobbiesRight = ['Sporty', 'Vývoj webových stránek a aplikací', 'Osobní rozvoj'];

const Interests = () => {
    return (
        <section className="py-5 border-bottom wow fadeInUp" data-wow-delay="0.1s">
            <h2 className="title pb-3 mb-5">Zájmy</h2>
            <div className="row g-3">
                <div className="col-sm-6">
                    <ul className="list-group">
                        {hobbiesLeft.map((hobby, index) => (
                            <li key={index} className="list-group-item my-1 bg-transparent text-light">
                                {hobby}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-sm-6">
                    <ul className="list-group">
                        {hobbiesRight.map((hobby, index) => (
                            <li key={index} className="list-group-item my-1 bg-transparent text-light">
                                {hobby}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Interests;
