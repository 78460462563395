import React from 'react';

const skillsData = [
    { name: 'HTML', level: '90%', bgColor: 'bg-primary', value: 90 },
    { name: 'CSS', level: '80%', bgColor: 'bg-light-green', value: 80 },
    { name: 'JavaScript', level: '90%', bgColor: 'bg-primary', value: 90 },
    { name: 'PHP', level: '65%', bgColor: 'bg-warning', value: 65 },
    { name: 'SQL', level: '60%', bgColor: 'bg-warning', value: 60 },
    { name: 'Java', level: '60%', bgColor: 'bg-warning', value: 60 },
    { name: 'React', level: '50%', bgColor: 'bg-warning', value: 50 },
    { name: 'Canva', level: '75%', bgColor: 'bg-light-green', value: 75 }
];

const Skill = ({ name, level, bgColor, value }) => (
    <div className="skill mb-4">
        <div className="d-flex justify-content-between">
            <p className="mb-2">{name}</p>
            <p className="mb-2">{level}</p>
        </div>
        <div className="progress">
            <div
                className={`progress-bar ${bgColor}`}
                role="progressbar"
                aria-valuenow={value}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${value}%` }}
            ></div>
        </div>
    </div>
);

const Skills = () => {
    return (
        <section className="py-5 border-bottom wow fadeInUp" data-wow-delay="0.1s">
            <h2 className="title pb-3 mb-5">Dovednosti</h2>
            <div className="row">
                <div className="col-sm-6">
                    {skillsData.slice(0, 4).map(skill => (
                        <Skill key={skill.name} {...skill} />
                    ))}
                </div>
                <div className="col-sm-6">
                    {skillsData.slice(4).map(skill => (
                        <Skill key={skill.name} {...skill} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Skills;

