import React, { useState } from 'react';

const portfolioItems = [
    {
        category: 'first',
        image: 'img/portfolio-Primatisk.webp',
        alt: 'Primatisk',
        link: 'https://www.primatisk.cz/',
        description: 'Webová stránka pro společnost Primatisk'
    },
    {
        category: 'first',
        image: 'img/portfolio-SKBrod.webp',
        alt: 'SK Brod',
        link: 'https://www.skbrod.cz/',
        description: 'Webová stránka pro fotbalový klub SK Český Brod'
    },
    {
        category: 'first',
        image: 'img/portfolio-LamaPB.webp',
        alt: 'LamaPB',
        link: 'https://hrdnk.cz/projekty/LamaPB/',
        description: 'Webová stránka pro společnost LamaPB'
    },
    {
        category: 'second',
        image: 'img/sk-cesky-brod-ig.png',
        alt: 'SK Český Brod',
        link: 'https://www.instagram.com/sk_cesky_brod/',
        description: 'Instagramová stránka pro SK Český Brod'
    }
];

const PortfolioItem = ({ category, image, alt, link, description }) => (
    <div className={`col-md-6 mb-4 portfolio-item ${category}`}>
        <div className="position-relative overflow-hidden mb-2">
            <img className="img-fluid w-100" src={image} alt={alt} />
            <div className="portfolio-btn d-flex align-items-center justify-content-center">
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {description}
                </a>
            </div>
        </div>
    </div>
);

const Portfolio = () => {
    const [filter, setFilter] = useState('*');

    const filteredItems = filter === '*' ? portfolioItems : portfolioItems.filter(item => item.category === filter);

    return (
        <section className="py-5 border-bottom wow fadeInUp" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
            <h2 className="title pb-3 mb-5">Portfolio vytvořených webových stránek</h2>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 text-center mb-2">
                            <ul className="list-inline mb-4" id="portfolio-flters">
                                <li className={`btn btn-secondary ${filter === '*' ? 'active' : ''}`} onClick={() => setFilter('*')}>
                                    <i className="fa fa-star me-2"></i>Všechny
                                </li>
                                <li className={`btn btn-secondary ${filter === 'first' ? 'active' : ''}`} onClick={() => setFilter('first')}>
                                    <i className="fa fa-laptop-code me-2"></i>Webové stránky
                                </li>
                                <li className={`btn btn-secondary ${filter === 'second' ? 'active' : ''}`} onClick={() => setFilter('second')}>
                                    <i className="fa fa-mobile-alt me-2"></i>Sociální sítě
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row portfolio-container">
                        {filteredItems.map((item, index) => (
                            <PortfolioItem key={index} {...item} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
