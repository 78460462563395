import React from 'react';

const servicesData = [
    {
        icon: 'fa-laptop-code',
        title: 'Tvorba webových stránek na zakázku',
        description: 'Nabízím návrh a tvorbu webových stránek na míru, včetně registrace domény a webhostingu.',
        mailto: 'mailto:jakub@hrdnk.cz?subject=Dotaz%20na%20tvorbu%20webových%20stránek'
    },
    {
        icon: 'fa-edit',
        title: 'Tvorba grafiky na sociální sítě',
        description: 'Poskytuji tvorbu banerů, úpravu fotek a návrh příspěvků pro sociální sítě.',
        mailto: 'mailto:jakub@hrdnk.cz?subject=Dotaz%20na%20tvorbu%20grafiky%20pro%20sociální%20sítě'
    }
];

const ServiceItem = ({ icon, title, description, mailto }) => (
    <div className="col-md-6">
        <div className="service-item">
            <i className={`fa fa-2x ${icon} mx-auto mb-4`}></i>
            <h5 className="mb-2">{title}</h5>
            <p className="mb-0">{description}</p>
            <a className="btn btn-dark mt-4" href={mailto}>Dotázat službu</a>
        </div>
    </div>
);

const Services = () => {
    return (
        <section className="py-5 border-bottom wow fadeInUp" data-wow-delay="0.1s">
            <h2 className="title pb-3 mb-5">Služby, které poskytuji</h2>
            <div className="row g-4">
                {servicesData.map((service, index) => (
                    <ServiceItem key={index} {...service} />
                ))}
            </div>
        </section>
    );
};

export default Services;
