import React from 'react';

const educationData = [
    {
        title: 'Vysoká škola ekonomická v Praze',
        faculty: 'Fakulta informatiky a statistiky',
        time: '2020 - 2024',
        description: 'Ukončené vysokoškolské bakalářské studium.'
    },
    {
        title: 'Smíchovská střední průmyslová škola',
        faculty: 'Informační technologie',
        time: '2016 - 2020',
        description: 'Ukončené středoškolské vzdělání s maturitou.'
    }
];

const EducationItem = ({ title, faculty, time, description }) => (
    <div className="position-relative mb-4">
        <span className="bi bi-arrow-right fs-4 text-light position-absolute" style={{ top: '-5px', left: '-50px' }}></span>
        <h5 className="mb-1">{title}</h5>
        <p className="mb-2">{faculty} | <small>{time}</small></p>
        <p>{description}</p>
    </div>
);

const Education = () => {
    return (
        <section className="py-5 wow fadeInUp" data-wow-delay="0.1s">
            <h2 className="title pb-3 mb-5">Studium</h2>
            <div className="border-start border-2 border-light pt-2 ps-5">
                {educationData.map((education, index) => (
                    <EducationItem key={index} {...education} />
                ))}
            </div>
        </section>
    );
};

export default Education;
