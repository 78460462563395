import React from 'react';

const About = () => {
    return (
        <section className="py-5 border-bottom wow fadeInUp" data-wow-delay="0.1s">
            <h2 className="title pb-3 mb-5">O mně</h2>
            <p>
                Aktuálně pracuji jako IT konzultant ve společnosti Neit Consulting s.r.o., konkrétně v divizi Team Assistant, která se zabývá procesním řízením.
                <br />
                Na volné noze se věnuji vývoji webových stránek a tvorbě grafického obsahu na sociální sítě.
                <br />
                Na začátku tohoto roku jsem dokončil své bakalářské studium na Fakultě informatiky a statistiky na VŠE, konkrétně obor aplikovaná informatika. Velmi rád bych ve svém studiu pokračoval právě zde, a to oborem Podniková informatika.
                <br />
                Ve volných chvílích velmi rád sportuji, konkrétně mám nejradši fotbal, kde mám možnost hrát za tým SK Český Brod ve městě, kde jsem vyrůstal. Dále rád zajdu do přírody a užívám si chvíle pro sebe.
                <br />
            </p>
            <div className="row mb-4">
                <div className="col-sm-12 py-1">
                    <span className="fw-medium text-primary">Jméno:</span> Jakub Hrdinka
                </div>
                <div className="col-sm-6 py-1">
                    <span className="fw-medium text-primary">Narozen:</span> 3. července 2000
                </div>
                <div className="col-sm-6 py-1">
                    <span className="fw-medium text-primary">Telefon:</span> +420 733 419 950
                </div>
                <div className="col-sm-6 py-1">
                    <span className="fw-medium text-primary">Vzdělání:</span> Vysokoškolské - bakalářské
                </div>
                <div className="col-sm-6 py-1">
                    <span className="fw-medium text-primary">Email: <u><a className="text-light" href="mailto:kuba.hrdinka@seznam.cz">kuba.hrdinka@seznam.cz</a></u></span>
                </div>
            </div>
        </section>
    );
};

export default About;
