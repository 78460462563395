import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            e.stopPropagation();
            e.target.reportValidity();
            return;
        }
        setLoading(true);
        const templateParams = {
            from_name: formData.name,
            to_name: 'Jakub Hrdinka',
            subject: formData.subject,
            message: formData.message,
            reply_to: formData.email
        };

        emailjs.send('service_huhy96o', 'template_vvfw93i', templateParams, 'bqxJi7tKQ2m87Qa0r')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setSuccessMessage('Email byl úspěšně odeslán.');
                setLoading(false);
            }, (err) => {
                console.log('FAILED...', err);
                setSuccessMessage('Nastala chyba při odesílání emailu.');
                setLoading(false);
            });

        setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
    };

    return (
        <section className="py-5 wow fadeInUp" data-wow-delay="0.1s" id="contact" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
            <h2 className="title pb-3 mb-5">Kontaktujte mě</h2>
            <p className="mb-4">V případě jakýchkoliv dotazů se mě nebojte kontaktovat.</p>
            {successMessage && <div id="alertMessage" className="alert alert-success">{successMessage}</div>}
            <form id="contactForm" onSubmit={handleSubmit} noValidate>
                <div className="row g-3">
                    <div className="col-sm-6 control-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control border-0 bg-secondary py-2 text-light"
                                id="name"
                                placeholder="Vaše jméno"
                                required
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <label htmlFor="name">Vaše jméno</label>
                        </div>
                    </div>
                    <div className="col-sm-6 control-group">
                        <div className="form-floating">
                            <input
                                type="email"
                                className="form-control border-0 bg-secondary py-2 text-light"
                                id="email"
                                placeholder="Váš email"
                                required
                                value={formData.email}
                                onChange={handleChange}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                title="Please enter a valid email address."
                            />
                            <label htmlFor="email">Váš email</label>
                            <div className="invalid-feedback">Zadejte platnou emailovou adresu.</div>
                        </div>
                    </div>
                    <div className="col-12 control-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control border-0 bg-secondary py-2 text-light"
                                id="subject"
                                placeholder="Předmět emailu"
                                required
                                value={formData.subject}
                                onChange={handleChange}
                            />
                            <label htmlFor="subject">Předmět emailu</label>
                        </div>
                    </div>
                    <div className="col-12 control-group">
            <textarea
                className="form-control border-0 bg-secondary py-2 text-light"
                rows="8"
                id="message"
                placeholder="Zpráva"
                required
                value={formData.message}
                onChange={handleChange}
            ></textarea>
                    </div>
                    <div className="col-12">
                        <button className="btn btn-primary w-100 py-2" type="submit" id="sendMessageButton">
                            <span>{loading ? 'Odesílání...' : 'Odeslat'}</span>
                            {loading && <div className="spinner-border spinner-border-sm text-light ms-3" role="status"></div>}
                        </button>
                    </div>
                </div>
            </form>
        </section>
    );
};

export default Contact;
